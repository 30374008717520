<template>
  <v-container
    fluid
  >
    <v-overlay
      v-if="loading"
      absolute
      :value="loading"
      opacity="1"
      color="white"
      class="full-screen"
      text-xs-center
    >
      <div class="d-flex flex-column justify-center align-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        />
        <img
          src="@/assets/Lavirra_logo_SVG.svg"
          height="28px"
          width="auto"
          class="mt-6"
        >
        <span
          class="mt-4 ml-2"
          :style="`font-size: 15px; color: ${$vuetify.theme.themes.light.primary};`"
        >
          {{ message }}
        </span>
      </div>
    </v-overlay>
    <v-card
      :style="`height: ${($vuetify.breakpoint.height - 72).toString() + 'px'}; overflow-x: hidden;`"
    >
      <v-toolbar
        flat
        width="100%"
        :rounded="false"
        class="form-toolbar"
      >
        <v-toolbar-title class="mt-4">
          {{ $t('payments|buy_more_member_seats') }}
        </v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col cols="4">
          <v-card
            elevation="8"
            class="mt-5 ml-5 sticky-card"
            max-width="600"
            :style="`height: ${($vuetify.breakpoint.height - 172).toString() + 'px'}; overflow-x: auto;`"
          >
            <v-card-title
              class="justify-center"
            >
              <h3 style="color: #234e79;">
                {{ $t('common|law_firm') }}/{{ $t('company|company') }}
              </h3>
            </v-card-title>
            <v-card-text
              class="text-center"
            >
              <div>
                <h4>{{ $t('members|current_subscription') }}:</h4>
                <span>{{ $t('members|actual_seats') }}: {{ company.seats }}</span><br>
              </div>
              <div>
                <span>{{ $t('members|new_seats') }}: {{ seats }}</span>
              </div>
              <div
                class="mt-8"
                style="font-weight: bold;"
              >
                {{ $t('common|total') }}: {{ getTotal() }}
              </div>
              <span v-if="company && company.subscriptionStatus === 'beta'">{{ $t('expressions|for_beta_users') }}</span>
              <span v-else>{{ $t('members|billed_montly') }}</span>
            </v-card-text>
            <v-divider class="px-2 divider-position-company" />
            <div class="element-position ml-4">
              <v-icon>mdi-check</v-icon>
              <a
                class="ml-2"
                href="https://lavvira.net"
                target="_blank"
              >
                {{ $t('expressions|all_core_features') }}
              </a><br>
              <v-icon>mdi-check</v-icon>
              <a
                class="ml-2"
                href="https://lavvira.net"
                target="_blank"
              >
                {{ $t('expressions|all_advanced_features') }}
              </a><br>
              <v-icon>mdi-check</v-icon>
              <span class="ml-2">{{ cfg.storageData.companyStorage }} {{ $t('common|storage') }}</span><br>
              <v-icon>mdi-check</v-icon>
              <span class="ml-2">{{ $t('common|premium_support') }}</span>
            </div>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-form
            ref="formRef"
          >
            <v-card-text>
              <v-row
                class="no-gutters"
              >
                <v-text-field
                  v-model="seats"
                  :label="$t('members|seats_amount')"
                  type="number"
                  minlength="1"
                  :rules="[(v) => !!v || 'Amount must be at least 1']"
                  required
                  outlined
                  dense
                  class="mt-2 mx-3"
                  oninput="if(this.value <= 0) this.value = 1;"
                />

                <v-col cols="12">
                  <v-card-title>
                    {{ $t('members|invite_more_members') + '(' + $t('common|optional') + ')' }}
                  </v-card-title>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="account.email"
                    outlined
                    dense
                    :label="$t('user|email')"
                    :hint="$t('user|enter_valid_email')"
                    persistent-hint
                    clearable
                    :disabled="true"
                    :append-icon="'mdi-trash-can'"
                    class="mx-3 my-2"
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    :label="'AdminOwner'"
                    :hint="$t('members|user_role')"
                    persistent-hint
                    :disabled="true"
                    dense
                    outlined
                    class="mr-2 my-2"
                  />
                </v-col>
              </v-row>
              <v-row
                v-for="(input, index) in memberFields"
                :key="`memberInput-${index}`"
                class="no-gutters"
              >
                <v-col cols="9">
                  <v-text-field
                    v-model="input.email"
                    outlined
                    dense
                    :label="$t('user|email')"
                    :hint="getTheRightHint(index, memberFields)"
                    persistent-hint
                    :rules="[(v) => !!v || $t('user|email_required')]"
                    required
                    clearable
                    :disabled="input.disabled"
                    :append-icon="'mdi-trash-can'"
                    class="mx-3 my-2"
                    @click:append="removeField(index, memberFields)"
                    @input="checkEmailExistence(index, memberFields)"
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="input.userRole"
                    :items="userRoles"
                    :label="$t('members|user_role')"
                    :hint="$t('common|field_required')"
                    persistent-hint
                    :rules="[(v) => !!v || $t('user|role_required')]"
                    required
                    :disabled="input.disabled"
                    dense
                    outlined
                    class="mr-2 my-2"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-btn
              rounded
              outlined
              small
              color="primary"
              class="mb-2 mt-4 ml-2"
              @click="addField()"
            >
              <v-icon
                small
                class="mr-1"
              >
                mdi-account-plus
              </v-icon>
              {{ $t('members|invite_new_member') }}
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              rounded
              small
              :disabled="hasPermission === false"
              min-width="150"
              class="mt-2"
              :loading="loading"
              @click="paymentProcessing"
            >
              {{ $t('payments|continue_to_checkout') }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
    <template>
      <StripeCheckout
        ref="checkoutRef"
        :pk="publishableKey"
        :session-id="sesId"
        @loading="v => loading = v"
      />
    </template>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import axios from '@/plugins/axios'
import cfg from '@/config'
// import autobahn from 'autobahn'

export default {
  components: {
    StripeCheckout
  },
  props: {
    activeSlide: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      sesId: null,
      dialog: false,
      companyName: '',
      loading: false,
      error: null,
      rules: [(v) => !!v || this.$t('fields|required')],
      hoverCloseButtonIcon: false,
      memberFields: [],
      userRoles: [
        'Regular',
        'Advanced',
        'Admin',
        'AdminOwner'
      ],
      subscriptionType: '',
      hasPermission: false,
      seats: 1,
      message: '',
      cfg
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    })
  },
  watch: {
    companyName (value) {
      if (value && value.length > 0) {
        this.hasPermission = true
      } else if (value && value.length <= 0) {
        this.hasPermission = false
      } else if (value === null) {
        this.hasPermission = false
      }
    },
    seats (val) {
      const value = Number(val)
      if (value && value > 1) {
        this.hasPermission = true
      }
    },
    memberFields: {
      handler (value) {
        if (!value && Number(this.seats) === 1) {
          console.log('1')
          this.hasPermission = false
        }
        if (!value.length && Number(this.seats) === 1) {
          console.log('2')
          this.hasPermission = false
        }
        if (!value && Number(this.seats) > 1) {
          console.log('3')
          this.hasPermission = true
        }
        if (!value.length && Number(this.seats) > 1) {
          console.log('4')
          this.hasPermission = true
        }
        if (value && value.length) {
          this.hasPermission =
        this.memberFields.length !== 0 &&
        this.memberFields.every(f => f.email !== null && (f.email.length > 0 && f.userRole) && f.verifiedEmail === 'email_exists')

          const duplicates = this.memberFields
            .map((el, i) => {
              return this.memberFields.find((element, index) => {
                if (i !== index && element.email && element.email.length && element.email === el.email) {
                  return el
                }
              })
            })
            .filter(Boolean)
          if (duplicates.length) {
            this.hasPermission = false
            this.addToast({
              title: this.$t('expressions|duplicate_emails'),
              color: 'white',
              snackbarColor: 'error'
            })
          }
        }
      },
      deep: true
    }
  },
  created () {
    EventBus.$on('dialog-create-company', this.onCreateCompanyEvent)
  },
  beforeDestroy () {
    EventBus.$off('dialog-create-company', this.onCreateCompanyEvent)
  },
  methods: {
    ...mapActions({
      addCompany: 'company/addCompany',
      updateCompanyMemberSeats: 'company/updateCompanyMemberSeats',
      addToast: 'toasts/addToast'
    }),
    ...mapMutations({
      SET_COMPANY: 'company/SET_COMPANY'
    }),
    addField () {
      const member = {
        email: '',
        userRole: '',
        verifiedEmail: 'not_tested'
      }
      this.memberFields.push(member)
      this.seats++
    },
    removeField (index, memberFields) {
      memberFields.splice(index, 1)
      this.seats--
    },
    onCreateCompanyEvent (size) {
      this.dialog = true
      this.companyName = ''
      this.memberFields = []
      this.hasPermission = false
      this.subscriptionType = size
    },
    close () {
      console.log('close')
      this.companyName = ''
      this.memberFields = []
      this.hasPermission = false
      this.dialog = false
      this.$refs.formRef.resetValidation()
    },
    getTotal () {
      const allSeats = this.company.seats + Number(this.seats)
      let actualPreice = Number(cfg.preices.companyAccountPreis)
      if (this.company && this.company.subscriptionStatus === 'beta') return '0' + ' ' + '€'
      else return (allSeats * actualPreice).toString() + ' ' + '€'
    },
    getTheRightHint (index, memberFields) {
      if (memberFields[index].verifiedEmail === 'not_tested') {
        return this.$t('expressions|enter_email_of_existing_lavvira_user')
      } else if (memberFields[index].verifiedEmail === 'email_exists') {
        return this.$t('common|email_exists')
      } else if (memberFields[index].verifiedEmail === 'email_does_not_exist') {
        return this.$t('common|email_does_not_exist')
      } else if (memberFields[index].verifiedEmail === 'user_has_already_been_invited') {
        return this.$t('expressions|user_has_already_been_invited')
      } else if (memberFields[index].verifiedEmail === 'participates_in_other_company') {
        return this.$t('expressions|user_participates_in_other_company')
      }
    },
    async checkEmailExistence (index, memberFields) {
      const emailToBeChecked = memberFields[index].email
      const payload = {
        email: emailToBeChecked
      }
      try {
        const { data } = await axios.post(`/emailExistenceCheck`, payload)
        if (data.success) {
          memberFields[index].verifiedEmail = 'email_exists'
        } else if (!data.success && data.msg && data.msg === 'already_invited') {
          memberFields[index].verifiedEmail = 'user_has_already_been_invited'
        } else if (!data.success && data.msg && data.msg === 'participates_in_other_company') {
          memberFields[index].verifiedEmail = 'participates_in_other_company'
        } else {
          memberFields[index].verifiedEmail = 'email_does_not_exist'
        }
      } catch (error) {
        console.error(error, 'email check')
      }
    },
    async paymentProcessing () {
      this.loading = true
      const _id = this.company._id
      const payload = {
        newSeats: this.seats,
        newInvitedMembers: this.memberFields,
        action: 'addSeats'
      }
      try {
        await this.updateCompanyMemberSeats({ _id, payload })
        this.addToast({
          title: this.$t('expressions|successfully_added_seats_in_company'),
          color: 'white',
          snackbarColor: 'success'
        })
        this.loading = false
        this.$router.push('/members')
      } catch (error) {
        console.error(error)
        this.loading = false
        this.addToast({
          title: this.$t('error|something_went_wrong'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
      // console.log('PAYMENT')
      // try {
      //   var connection = new autobahn.Connection({
      //     url: process.env.VUE_APP_CROSSBAR_PATH,
      //     realm: process.env.VUE_APP_CROSSBAR_REALM
      //   })
      //   connection.onopen = async (session) => {
      //     const res = await session.call('lavvira.stripe_controller.update_subscription_amount',
      //       [
      //         this.account.companyId,
      //         this.seats
      //       ])
      //     console.log(res, 'RES')
      //     if (res && res.success) {
      //       this.addToast({
      //         title: this.$t('expressions|successfully_added_seats_in_company'),
      //         color: 'white',
      //         snackbarColor: 'success'
      //       })
      //       // store
      //       this.SET_COMPANY(res.company)
      //     } else {
      //       this.loading = false
      //       this.addToast({
      //         title: this.$t('error|something_went_wrong'),
      //         color: 'white',
      //         snackbarColor: 'error'
      //       })
      //       // this.$router.push('/members')
      //     }
      //   }
      //   connection.onclose = function (reason, details) {
      //     // handle connection lost
      //     console.log(reason, details, 'connection.onclose')
      //   }
      //   connection.open()
      // } catch (e) {
      //   console.log(e, 'WAMP ERROR')
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  position: relative;
  display: flex;
  flex-direction: column;
}
.form-toolbar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
}
.sticky-card {
  position: sticky;
  top: 33px;
  left: 0;
  z-index: 1;
}
.element-position {
  position: absolute;
  width: 90%;
  bottom: 30px;
}
.divider-position-company {
  position: absolute;
  width: 100%;
  bottom: 160px;
}
.full-screen {
    height: 100%;
    width: 100%;
  }
</style>
